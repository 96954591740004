jQuery.noConflict();
(function($) {
$( document ).ready(function() {
// andherewego.exe



/*******************************
// mobile subnavigation
*******************************/
$('.opensub').click(function(event){
    $(this).toggleClass('open');
    $('#sidenav').slideToggle('fast');
    if($('#sidenav').hasClass('open')) {
        $('#sidenav').removeClass('open');
    } else {
        $('#sidenav').addClass('open');
    }
});


// Team Page
var $grid = $('#theteam');
setTimeout(function(){
    $(window).trigger('resize');
    $('#joli-day-spa').trigger('click');
    console.log('loaded everything');
    $grid.isotope('reloadItems');
    Foundation.reInit('equalizer');
}, 2000);


/*******************************
// join our team
*******************************/
$('.accordion').click(function(event){
    $(this).next('div').slideToggle('fast');
});




/*******************************
// foxycart gift cards equalizer fix
*******************************/
$('#gift_delivery').click(function() {
    setTimeout(function(){
        $('[data-equalizer]').foundation('_reflow');
    }, 500);
});



// end it all
});
})(jQuery);